import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'reactstrap-date-picker';
import classnames from 'classnames';
import ReactPaginate from 'react-paginate';

export default function AdminServicesPage({
  // Filtros
  statusFilter,
  setStatusFilter,
  customerNameFilter,
  setCustomerNameFilter,
  driverNameFilter,
  setDriverNameFilter,
  deliveryDateFilter,
  setDeliveryDateFilter,
  deliveryDateFromFilter,
  setDeliveryDateFromFilter,
  deliveryDateUntilFilter,
  setDeliveryDateUntilFilter,
  isPaidFilter,
  setisPaidFilter,
  activeDateFilterOption,
  setActiveDateFilterOption,

  // Estado y funciones de los filtros
  areFiltersVisible,
  toggleAreFiltersVisible,
  applyFilters,
  clearFilters,

  // Servicios
  services,
  filteredServices,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const displayedServices = filteredServices || services;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = displayedServices.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Button
          onClick={toggleAreFiltersVisible}
          className="whiteSecondaryBtn"
          style={{ marginLeft: '1rem' }}
        >
          Filtros{' '}
          {areFiltersVisible ? (
            <FaChevronUp style={{ width: 15, height: 15 }} />
          ) : (
            <FaChevronDown style={{ width: 15, height: 15 }} />
          )}
        </Button>
      </div>
      <div>
        {areFiltersVisible && (
          <div className="filtersContainer" style={{ marginBottom: '1rem' }}>
            <p className="label">Status</p>
            <Input
              type="select"
              className="mainInput"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              defaultValue={'default'}
            >
              <option value={'default'} disabled>
                El status del servicio
              </option>
              <option value={'Buscando Chofer'}>Buscando Chofer</option>
              <option value={'Chofer Encontrado'}>Chofer Encontrado</option>
              <option value={'Pagado'}>Pagado</option>
              <option value={'Amazon Enviando'}>Amazon Enviando</option>
              <option value={'Recogiendo Paquete'}>Recogiendo Paquete</option>
              <option value={'Paquete en Camino'}>Paquete en Camino</option>
              <option value={'Enviando Paquete'}>Enviando Paquete</option>
              <option value={'Completado'}>Completado</option>
            </Input>
            <p className="label">Nombre del cliente</p>
            <Input
              type="text"
              className="mainInput"
              value={customerNameFilter}
              onChange={(e) => setCustomerNameFilter(e.target.value)}
              placeholder="El nombre del cliente"
            />
            <p className="label">Nombre del viajero</p>
            <Input
              type="text"
              className="mainInput"
              value={driverNameFilter}
              onChange={(e) => setDriverNameFilter(e.target.value)}
              placeholder="El nombre del viajero"
            />
            <p className="label">Fecha de entrega</p>
            <div className="tabBar" style={{ marginBottom: '0.5rem' }}>
              <Button
                onClick={() => setActiveDateFilterOption(1)}
                className={classnames('tabButton', {
                  active: activeDateFilterOption === 1,
                })}
                style={{ marginRight: '0.5rem' }}
              >
                Fecha
              </Button>
              <Button
                onClick={() => setActiveDateFilterOption(2)}
                className={classnames('tabButton', {
                  active: activeDateFilterOption === 2,
                })}
              >
                Rango
              </Button>
            </div>
            {activeDateFilterOption === 1 ? (
              <DatePicker
                id="example-datepicker"
                className="mainInput"
                style={{ borderRadius: 10 }}
                showClearButton={false}
                value={deliveryDateFilter}
                onChange={(v) => setDeliveryDateFilter(v)}
                dateFormat="DD.MM.YYYY"
                placeholder="La fecha de entrega del servicio"
              />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  id="example-datepicker"
                  className="mainInput"
                  style={{ borderRadius: 10, marginRight: '0.5rem' }}
                  showClearButton={false}
                  value={deliveryDateFromFilter}
                  onChange={(v) => setDeliveryDateFromFilter(v)}
                  dateFormat="DD.MM.YYYY"
                  placeholder="La fecha de entrega del servicio desde"
                />
                <DatePicker
                  id="example-datepicker"
                  className="mainInput"
                  style={{ borderRadius: 10 }}
                  showClearButton={false}
                  value={deliveryDateUntilFilter}
                  onChange={(v) => setDeliveryDateUntilFilter(v)}
                  dateFormat="DD.MM.YYYY"
                  placeholder="La fecha de entrega del servicio hasta"
                />
              </div>
            )}
            <p className="label">Pagado</p>
            <Input
              type="select"
              className="mainInput"
              value={isPaidFilter}
              onChange={(e) => setisPaidFilter(e.target.value)}
              defaultValue={'default'}
            >
              <option value={'default'} disabled>
                Si el servicio ha sido pagado o no
              </option>
              <option value={true}>Pagado</option>
              <option value={false}>No pagado</option>
            </Input>
            <Button
              onClick={applyFilters}
              className="primaryBtn"
              style={{ marginTop: '1rem' }}
              disabled={
                !statusFilter &&
                !customerNameFilter &&
                !driverNameFilter &&
                !deliveryDateFilter &&
                !deliveryDateFromFilter &&
                !deliveryDateUntilFilter &&
                !isPaidFilter
              }
            >
              Aplicar
            </Button>
            {filteredServices && (
              <Button
                onClick={clearFilters}
                className="secondaryBtn"
                style={{ marginTop: '1rem' }}
              >
                Limpiar
              </Button>
            )}
          </div>
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Cliente</th>
            <th>Viajero</th>
            <th>Fecha</th>
            <th>Destino</th>
            <th>Productos</th>
            <th>Total</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} onClick={() => navigate(`/service/${item.id}`)}>
              <td data-title="ID">{item.id}</td>
              <td data-title="Cliente">{item.senderName}</td>
              <td data-title="Viajero">{item.driverName || 'N/A'}</td>
              <td data-title="Fecha">
                {
                  item.createdAt
                    .toDate()
                    .toLocaleDateString('en-US')
                    .split('/')[1]
                }
                .
                {
                  item.createdAt
                    .toDate()
                    .toLocaleDateString('en-US')
                    .split('/')[0]
                }
                .
                {
                  item.createdAt
                    .toDate()
                    .toLocaleDateString('en-US')
                    .split('/')[2]
                }
              </td>
              <td data-title="Destino">{item.destination.country}</td>
              <td data-title="Productos">{item.products.length}</td>
              <td data-title="Total">${item.total.total}</td>
              <td data-title="Estado">{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(displayedServices.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
}
