import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Timestamp } from '@firebase/firestore';
import {
  query,
  getDocs,
  collection,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { DatePicker } from 'reactstrap-date-picker';
import { MdChevronLeft } from 'react-icons/md';
import { PiLinkBold } from 'react-icons/pi';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Input, Button } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminEditServicePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [serviceSenderName, setServiceSenderName] = useState();
  const [serviceType, setServiceType] = useState();
  const [serviceDateCreated, setServiceDateCreated] = useState();
  const [serviceArrivalDate, setServiceArrivalDate] = useState();
  const [serviceDestinationCountry, setServiceDestinationCountry] = useState();
  const [serviceDestinationAddress, setServiceDestinationAddress] = useState();
  const [serviceDriverName, setServiceDriverName] = useState();
  const [serviceStatus, setServiceStatus] = useState();
  const [serviceIsPayed, setServiceIsPayed] = useState();
  const [serviceReward, setServiceReward] = useState();
  const [serviceProductsCost, setServiceProductsCost] = useState();
  const [serviceFeeCost, setServiceFeeCost] = useState();
  const [serviceDeliveryCost, setServiceDeliveryCost] = useState();
  const [serviceHomeDeliveryCost, setServiceHomeDeliveryCost] = useState();
  const [serviceTotalCost, setServiceTotalCost] = useState();
  const [serviceProducts, setServiceProducts] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const [service, setService] = useState();
  useEffect(() => {
    const fetchServiceData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const q = query(collection(db, 'Services'), where('id', '==', id));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
        setService(data);
        setServiceId(data.id);
        setServiceSenderName(data.senderName);
        setServiceType(data.type);
        setServiceDateCreated(data.createdAt);
        setServiceArrivalDate(data.arrivalDate.toDate());
        setServiceDestinationCountry(data.destination.country);
        setServiceDestinationAddress(data.destination.address);
        setServiceDriverName(data.driverName);
        setServiceStatus(data.status);
        setServiceIsPayed(data.payed);
        setServiceReward(data.reward);
        setServiceProductsCost(data.total.products);
        setServiceFeeCost(data.total.fee);
        setServiceDeliveryCost(data.total.delivery);
        setServiceHomeDeliveryCost(data.total.homeDelivery);
        setServiceTotalCost(data.total.total);
        setServiceProducts(data.products);
      } catch {
        CustomToast('error', 'No se pudo cargar la información del servicio');
      } finally {
        setIsLoading(false);
      }
    };
    fetchServiceData();
  }, [id]);

  const [isEdittingDeliveryAddress, setIsEdittingDeliveryAddress] =
    useState(false);

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      let newAddressLat = 0;
      let newAddressLng = 0;
      if (!(serviceDestinationAddress === service?.destination.address)) {
        geocodeByAddress(serviceDestinationAddress.label)
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            newAddressLat = lat;
            newAddressLng = lng;
          });
      }
      const serviceRef = query(
        collection(db, 'Services'),
        where('id', '==', id),
      );
      const findServices = await getDocs(serviceRef);
      findServices.forEach(async (service) => {
        const getService = doc(db, 'Services', service.id);
        const serviceData = findServices.docs[0].data();
        await updateDoc(getService, {
          status: serviceStatus,
          arrivalDate: Timestamp.fromDate(new Date(serviceArrivalDate)),
          destination: {
            address:
              serviceDestinationAddress === serviceData?.destination.address
                ? serviceData?.destination.address
                : serviceDestinationAddress.value.terms[0].value,
            country:
              serviceDestinationAddress === serviceData?.destination.address
                ? serviceData?.destination.country
                : serviceDestinationAddress.value.terms[2]
                  ? serviceDestinationAddress.value.terms[2].value
                  : serviceDestinationAddress.value.terms[0].value,
            placeId:
              serviceDestinationAddress === serviceData?.destination.address
                ? serviceData?.destination.placeId
                : serviceDestinationAddress.value.place_id,
            location: {
              lat:
                serviceDestinationAddress === serviceData?.destination.address
                  ? serviceData?.destination.location.lat
                  : newAddressLat,
              lng:
                serviceDestinationAddress === serviceData?.destination.address
                  ? serviceData?.destination.location.lng
                  : newAddressLng,
            },
            total: {
              delivery: parseFloat(serviceDeliveryCost),
              fee: parseFloat(serviceFeeCost),
              homeDelivery: parseFloat(serviceHomeDeliveryCost),
              products: parseFloat(serviceProductsCost),
              total: parseFloat(
                serviceDeliveryCost +
                  serviceFeeCost +
                  serviceHomeDeliveryCost +
                  serviceProductsCost,
              ),
            },
          },
        });
      });
    } catch {
      CustomToast('error', 'No se pudo guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDeliveryAddressEdit = () => {
    setIsEdittingDeliveryAddress(false);
    setServiceDestinationAddress(service?.destination.address);
  };
  if (isLoading) return <PageLoader />;
  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Servicio
      </h2>
      <p className="label">ID</p>
      <Input
        type="text"
        className="mainInput"
        value={serviceId}
        onChange={(e) => setServiceId(e.target.value)}
        placeholder="El ID del servicio"
        disabled
      />
      <p className="label">Cliente</p>
      <Input
        type="text"
        className="mainInput"
        value={serviceSenderName}
        onChange={(e) => setServiceSenderName(e.target.value)}
        placeholder="El ID del servicio"
        disabled
      />
      <p className="label">Tipo</p>
      <Input
        type="text"
        className="mainInput"
        value={serviceType}
        onChange={(e) => setServiceType(e.target.value)}
        placeholder="El tipo del servicio"
        disabled
      />
      <p className="label">Fecha creada</p>
      <Input
        type="text"
        className="mainInput"
        value={
          serviceDateCreated
            ? `${serviceDateCreated.toDate().toLocaleDateString('en-US').split('/')[1]}.${serviceDateCreated.toDate().toLocaleDateString('en-US').split('/')[0]}.${serviceDateCreated.toDate().toLocaleDateString('en-US').split('/')[2]}`
            : null
        }
        onChange={(e) => setServiceDateCreated(e.target.value)}
        placeholder="La fecha creada del servicio"
        disabled
      />
      <p className="label">Fecha de entrega</p>
      <DatePicker
        id="example-datepicker"
        className="mainInput"
        style={{ borderRadius: 10 }}
        showClearButton={false}
        value={serviceArrivalDate}
        onChange={(v) => setServiceArrivalDate(v)}
        dateFormat="DD.MM.YYYY"
        placeholder="La fecha de entrega del servicio"
      />
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Destino</p>
        <p className="label">Pais de destino</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceDestinationCountry}
          onChange={(e) => setServiceDestinationCountry(e.target.value)}
          placeholder="El pais de destino del servicio"
          disabled
        />
        <p className="label">
          Dirección de destino
          {serviceHomeDeliveryCost === 0 ? ' (En sucursal)' : null}
        </p>
        {isEdittingDeliveryAddress ? (
          <GooglePlacesAutocomplete
            className="mainInput"
            selectProps={{
              serviceDestinationAddress,
              onChange: setServiceDestinationAddress,
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          />
        ) : (
          <Input
            type="text"
            className="mainInput"
            value={serviceDestinationAddress}
            onChange={(e) => setServiceDestinationAddress(e.target.value)}
            placeholder="La dirección de destino del servicio"
            disabled
          />
        )}
        {isEdittingDeliveryAddress ? (
          <Button
            onClick={cancelDeliveryAddressEdit}
            className="primaryBtn"
            style={{ marginTop: '1rem' }}
          >
            Cancelar
          </Button>
        ) : (
          <Button
            onClick={() => setIsEdittingDeliveryAddress(true)}
            className="primaryBtn"
          >
            Cambiar destino
          </Button>
        )}
      </div>
      <p className="label">Viajero</p>
      <Input
        type="text"
        className="mainInput"
        value={serviceDriverName}
        onChange={(e) => setServiceDriverName(e.target.value)}
        placeholder="El driver del servicio"
        disabled
      />
      <p className="label">Is payed</p>
      <Input
        type="text"
        className="mainInput"
        value={serviceIsPayed?.toString()}
        onChange={(e) => setServiceIsPayed(e.target.value)}
        placeholder="Si el servicio ha sido pagado aun o no"
        disabled
      />
      <p className="label">Status</p>
      <Input
        type="select"
        className="mainInput"
        value={serviceStatus}
        onChange={(e) => setServiceStatus(e.target.value)}
        placeholder="El status del servicio"
      >
        {serviceType === 'Amazon' ? (
          <>
            <option value="Pagado">Pagado</option>
            <option value="Amazon Enviando">Amazon Enviando</option>
            <option value="Paquete en Camino">Paquete en Camino</option>
            <option value="Completado">Completado</option>
          </>
        ) : (
          <>
            <option value="Pagado">Pagado</option>
            <option value="Recogiendo Paquete">Recogiendo Paquete</option>
            <option value="Enviando Paquete">Enviando Paquete</option>
            <option value="Completado">Completado</option>
          </>
        )}
      </Input>
      <p className="label">Reward</p>
      <Input
        type="text"
        className="mainInput"
        value={'$' + serviceReward}
        onChange={(e) => setServiceReward(e.target.value)}
        placeholder="El reward del servicio"
        disabled
      />
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Costos</p>
        <p className="label">Productos</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceProductsCost}
          onChange={(e) => setServiceProductsCost(e.target.value)}
          placeholder="El reward del servicio"
          disabled
        />
        <p className="label">Fee</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceFeeCost}
          onChange={(e) => setServiceFeeCost(e.target.value)}
          placeholder="El reward del servicio"
          disabled
        />
        <p className="label">Delivery</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceDeliveryCost}
          onChange={(e) => setServiceDeliveryCost(e.target.value)}
          placeholder="El reward del servicio"
          disabled
        />
        <p className="label">Home delivery</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceHomeDeliveryCost}
          onChange={(e) => setServiceHomeDeliveryCost(e.target.value)}
          placeholder="El reward del servicio"
          disabled
        />
        <p className="label">Total</p>
        <Input
          type="text"
          className="mainInput"
          value={serviceTotalCost}
          onChange={(e) => setServiceTotalCost(e.target.value)}
          placeholder="El reward del servicio"
          disabled
        />
      </div>
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Productos</p>
        <ScrollMenu>
          {serviceProducts?.map((item, index) => {
            return (
              <div key={index} className="productContainer">
                <img alt="logo" src={item?.image} />
                <p className="productName">{item?.title}</p>
                <p className="productBrand">{item?.brand}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p className="productPrice">{item?.price.raw}</p>
                  <Link
                    to={item?.link}
                    rel="noreferrer"
                    className="productLink"
                  >
                    <PiLinkBold />
                  </Link>
                </div>
              </div>
            );
          })}
        </ScrollMenu>
      </div>
      <Button
        onClick={saveChanges}
        className="primaryBtn"
        disabled={
          serviceStatus === service?.status &&
          serviceDestinationAddress === service?.destination.address &&
          new Date(serviceArrivalDate).toDateString() ===
            new Date(service?.arrivalDate.toDate()).toDateString()
        }
      >
        Guardar
      </Button>
    </div>
  );
}
