import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { formatDateISO } from '../../../util/date';

export default function AdminOrdersPage({ listOrders, redirectDetailsOrder }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = listOrders.filter(
        (order) =>
          order.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          order.customerName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.product?.asin
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.product?.nameProduct
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.product?.urlProduct
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.traveler?.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order.product?.nameShop
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()),
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(listOrders);
    }
  }, [searchQuery, listOrders]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '0.25rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          onClick={() => navigate('/order/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      {filteredOrders?.length > 0 ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>Codigo del Pedido</th>
                <th>Cliente</th>
                <th>Viajero</th>
                <th>Fecha</th>
                <th>Pendiente de pago</th>
                <th>Total</th>
                <th>Tienda</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item, index) => (
                <tr key={index} onClick={() => navigate(`/order/${item.id}`)}>
                  <td data-title="Codigo del Pedido">{item.id}</td>
                  <td data-title="Cliente">{item.customerName}</td>
                  <td data-title="Viajero">{item?.traveler?.name || 'N/A'}</td>
                  <td data-title="Fecha">
                    {formatDateISO(new Date(item.createdAt))}
                  </td>
                  <td data-title="Pendiente de Pago">
                    $
                    {parseFloat(item.total || 0) -
                      parseFloat(item.canceledAmount || 0) >
                    0
                      ? (
                          parseFloat(item.total || 0) -
                          parseFloat(item.canceledAmount || 0)
                        ).toFixed(2)
                      : '0'}
                  </td>
                  <td data-title="Total">
                    ${parseFloat(item.total).toFixed(2)}
                  </td>
                  <td data-title="Tienda">
                    {item.product?.nameShop
                      .toLowerCase()
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                  </td>
                  <td data-title="Estatus">{item.status}</td>
                  <td data-title="Acciones">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        redirectDetailsOrder(e, item);
                      }}
                      style={{
                        backgroundColor: '#FFD000',
                        borderRadius: 10,
                        border: 'none',
                        color: 'black',
                        fontWeight: '600',
                      }}
                    >
                      Ver Detalles
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Siguiente'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(filteredOrders.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            previousClassName={'previous'}
            nextClassName={'next'}
            disabledClassName={'disabled'}
          />
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>Aun no hay pedidos</p>
      )}
    </div>
  );
}
